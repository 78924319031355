import React from 'react';
import { useRouter } from 'next/router';
import { CustomAppProps } from '~/app/App';
import { AuthProvider } from '~/common/providers/auth/AuthProvider';
import { LoginContainer } from '~/features/login/LoginContainer';
import { asSafely, isString, isUndefined } from '~/common/utils/as-safely';

function LoginPage(): JSX.Element {
  const { query } = useRouter();
  return (
    <AuthProvider>
      <LoginContainer
        invitationId={asSafely(query['invitation'], [isString, isUndefined])}
        organizationId={asSafely(query['organization'], [isString, isUndefined])}
        returnTo={asSafely(query['returnTo'], [isString, isUndefined])}
      />
    </AuthProvider>
  );
}

export const getStaticProps = async (): Promise<CustomAppProps> => ({
  props: {
    noAuth: true,
  },
});

export default LoginPage;
