import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
type LoginContainerProps = { invitationId?: string; organizationId?: string; returnTo?: string };

function LoginContainer({
  invitationId,
  organizationId,
  returnTo,
}: LoginContainerProps): JSX.Element {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        invitation: invitationId,
        organization: organizationId,
      },
      appState: {
        returnTo,
      },
    });
  }, [loginWithRedirect, invitationId, organizationId, returnTo]);

  return <></>;
}

export { LoginContainer };
